import { useIntl } from "react-intl";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { NavBarConst } from "../../../constants/NavBarConst";
import { createContext, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../utils/CommonHelper";
import { useAuth } from "../../auth/components/AuthInit";
import SweetAlert2 from "react-sweetalert2";
import { FaStar } from "react-icons/fa6";
import { CgCloseO } from "react-icons/cg";
import { DownloadDetail } from "./Download";
const MasterLayoutContext = createContext();

export const MasterLayout = () => {
  const intl = useIntl();
  const { logout } = useAuth();
  const [swalProps, setSwalProps] = useState({});
  const [isVisible, setIsVisible] = useState(true);
  const [downloadDetailsVisible, setDownloadDetailsVisible] = useState(false);
  const masterLayoutState = useSelector(
    ({ masterLayout }) => masterLayout,
    shallowEqual
  );
  const navigate = useNavigate();
  const value = {};
  const imageUrl = toAbsoluteUrl("/image/logo/logo1.png");
  useEffect(() => {
    const handleTokenChange = () => {
      setSwalProps({
        show: true,
        icon: "error",
        text: "Please avoid logging in with the same account\n请避免使用同一账号登入\nSila elakkan log masuk dengan  akaun yan sama",
      });
    };

    window.addEventListener("sessionLost", handleTokenChange);

    return () => {
      window.removeEventListener("sessionLost", handleTokenChange);
    };
  }, []);
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const toggleDownload = () => {
    setDownloadDetailsVisible(true);
  };

  // useEffect(() => {
  //   const isMobile = sessionStorage.getItem("platform") === "mobile";
  //   const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  //   if (isMobile || isSafari) {
  //     document.querySelector(".bottom-download").style.display = "none";

  //     return () => {
  //       document.querySelector(".bottom-download").style.display = "flex";
  //     };
  //   }
  // });

  return (
    <>
      <SweetAlert2
        {...swalProps}
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
          logout();
        }}
      />
      <DownloadDetail
        setSwalProps={setSwalProps}
        isDownloadModalVisible={downloadDetailsVisible}
        setDownloadDetailsVisible={setDownloadDetailsVisible}
        onClose={() => setDownloadDetailsVisible(false)}
      />
      <MasterLayoutContext.Provider value={value}>
        <div className="masterPage-content">
          <Outlet />
        </div>
      </MasterLayoutContext.Provider>
    </>
  );
};
