import React from "react";
import PropTypes from "prop-types";
import "./Modal.css";

export const Modal = ({
  modalId,
  title,
  subtitle,
  children,
  dismissBtnRef,
  isVisible,
  onClose,
}) => {
  const modalClass = isVisible ? "modal fade show" : "modal fade";
  const backdropClass = isVisible ? "modal-backdrop fade show" : "";

  // Ensure the modal is removed from the DOM after it's closed
  const handleModalClose = () => {
    onClose();
    document.body.classList.remove("modal-open");
  };

  if (!isVisible) return null;

  return (
    <>
      <div className={backdropClass}></div>
      <div
        className={modalClass}
        id={modalId}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: "block" }}
        onClick={handleModalClose}
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ margin: "0 auto" }}
          onClick={(e) => e.stopPropagation()} // Prevent modal close on dialog click
        >
          <div className="modal-content modalBackground">
            <div className="d-flex align-items-center justify-content-between custom-border-bottom p-3">
              <h1
                className="modal-title text-font4"
                id="exampleModalLabel"
                style={{ width: "100%" }}
              >
                {title}
              </h1>
              <i
                className="bi bi-x modal-close cursor-pointer fs-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
                ref={dismissBtnRef}
              />
            </div>
            <div>
              <h6 className="modal-title mx-3"> {subtitle}</h6>
            </div>
            <div className="modal-body p-3 text-font3">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

Modal.propTypes = {
  modalId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  dismissBtnRef: PropTypes.object,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
