import { Modal } from "../../../common/components/Modal1";
import Skeleton from "react-loading-skeleton";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/LotteryAction";
import { useEffect, useRef, useState } from "react";
import {
  LotteryBetTypeConst,
  LotteryTypeConst,
  LotteryTypeTitleConst,
} from "../../../../constants/LotteryNavConst";
import { useIntl } from "react-intl";
import {
  LotteryHLCompanyConst,
  LotteryMKTCompanyConst,
} from "../../../../constants/LotteryCompanyConst";
import { toAbsoluteUrl } from "../../../../utils/CommonHelper";

export const LotteryPayoutDialog = ({
  setSwalProps,
  currentQues,
  isVisible,
  onClose,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const dismissBtnRef = useRef();
  const [lotteryType, setLotteryType] = useState(
    LotteryTypeConst.BASIC_LOTTERY
  );
  const lotteryState = useSelector(({ lottery }) => lottery, shallowEqual);

  useEffect(() => {
    const queryParams = {
      action: "prize4dlist",
    };
    dispatch(actions.getLotteryPrizeUI(queryParams));
  }, []);

  const getLotteryPrize = (lotteryBetType) => {
    const betPrizeList =
      lotteryType === LotteryTypeConst.BASIC_LOTTERY
        ? lotteryState.lotteryPrizeUI.mKTPrizeLists
        : lotteryState.lotteryPrizeUI.hLPrizeLists;

    return betPrizeList.find(
      (betPrize) => betPrize.bettypedscp === lotteryBetType
    ).betType;
  };

  // const handleCancel = () => {
  //   if (dismissBtnRef.current) {
  //     dismissBtnRef.current.click();
  //   }
  // };

  return (
    <Modal
      modalId="lotteryPayoutModal"
      title={intl.formatMessage({ id: "lotteryPayout" })}
      dismissBtnRef={dismissBtnRef}
      isVisible={isVisible}
      onClose={onClose}
    >
      <div>
        <div className="d-flex mb-4 my-2">
          {Object.entries(LotteryTypeConst).map(([key, value], index) => (
            <div
              key={index}
              className={`p-2 cursor-pointer ws-nowrap text-center`}
              style={{
                flex: 1,
                maxWidth: "50%",
                background: "rgba(255,255,255,0.1)",
                color: lotteryType === value ? "rgb(245, 190, 9)" : "",
                borderRight:
                  index === 0 ? "1px solid rgba(255,255,255,0.2)" : "none",
              }}
              onClick={() => {
                setLotteryType(value);
              }}
            >
              {intl.formatMessage({ id: LotteryTypeTitleConst[index].title })}
            </div>
          ))}
        </div>

        {lotteryState.listLoading || !lotteryState.lotteryPrizeUI ? (
          <>
            <Skeleton className="w-100" style={{ height: "500px" }} />
          </>
        ) : (
          <div className="text-font2">
            <div className="d-flex align-items-center mb-3 overflow-scroll">
              {(lotteryType === LotteryTypeConst.HL_LOTTERY
                ? LotteryHLCompanyConst
                : LotteryMKTCompanyConst
              ).map((lotteryCompany, index) => (
                <div key={index}>
                  <img
                    style={{ width: "30px" }}
                    className="mx-1"
                    src={toAbsoluteUrl(lotteryCompany.lotteryUrl)}
                    alt={lotteryCompany.lotteryCode}
                  />
                </div>
              ))}
            </div>
            <div className="d-flex custom-border ">
              <div className="col-4 p-2 custom-border-end bg-light text-dark text-center">
                {intl.formatMessage({ id: "prize" }).toUpperCase()}
              </div>
              <div className="d-flex col-8">
                <div
                  className="col-4 p-2  custom-border-end text-dark text-center"
                  style={{
                    backgroundColor: currentQues === "b" ? "#f5be09" : "white",
                  }}
                >
                  {intl.formatMessage({ id: "big" }).toUpperCase()}
                </div>
                <div
                  className="col-4 p-2  custom-border-end text-dark text-center"
                  style={{
                    backgroundColor: currentQues === "s" ? "#f5be09" : "white",
                  }}
                >
                  {intl.formatMessage({ id: "small" }).toUpperCase()}
                </div>
                <div
                  className="col-4 p-2  text-dark text-center"
                  style={{
                    backgroundColor: currentQues === "4a" ? "#f5be09" : "white",
                  }}
                >
                  {4 + intl.formatMessage({ id: "a" }).toUpperCase()}
                </div>
              </div>
            </div>
            <div className="d-flex custom-border">
              <div className="col-4 p-2  custom-border-end">
                {intl.formatMessage({ id: "firstPrize" })}
              </div>
              <div className="d-flex col-8">
                <div
                  className="col-4 custom-border-end p-2 fw-semibold text-end"
                  style={{
                    backgroundColor: currentQues === "b" ? "#f5be09" : "",
                    color: currentQues === "b" ? "black" : "",
                  }}
                >
                  {getLotteryPrize(LotteryBetTypeConst.BIG).prize1}
                </div>
                <div
                  className="col-4 custom-border-end p-2 fw-semibold text-end"
                  style={{
                    backgroundColor: currentQues === "s" ? "#f5be09" : "",
                    color: currentQues === "s" ? "black" : "",
                  }}
                >
                  {getLotteryPrize(LotteryBetTypeConst.SMALL).prize1}
                </div>
                <div
                  className="col-4 p-2 fw-semibold text-end"
                  style={{
                    backgroundColor: currentQues === "4a" ? "#f5be09" : "",
                    color: currentQues === "4a" ? "black" : "",
                  }}
                >
                  {getLotteryPrize(LotteryBetTypeConst.FOUR_A).prize1}
                </div>
              </div>
            </div>
            <div className="d-flex custom-border">
              <div className="col-4 p-2  custom-border-end">
                {intl.formatMessage({ id: "secondPrize" })}
              </div>
              <div className="d-flex col-8">
                <div
                  className="col-4 custom-border-end p-2 fw-semibold text-end "
                  style={{
                    backgroundColor: currentQues === "b" ? "#f5be09" : "",
                    color: currentQues === "b" ? "black" : "",
                  }}
                >
                  {getLotteryPrize(LotteryBetTypeConst.BIG).prize2}
                </div>
                <div
                  className="col-4 custom-border-end p-2 fw-semibold text-end"
                  style={{
                    backgroundColor: currentQues === "s" ? "#f5be09" : "",
                    color: currentQues === "s" ? "black" : "",
                  }}
                >
                  {getLotteryPrize(LotteryBetTypeConst.SMALL).prize2}
                </div>
                <div
                  className="col-4 p-2 fw-semibold text-end"
                  style={{
                    backgroundColor: currentQues === "4a" ? "#f5be09" : "",
                    color: currentQues === "4a" ? "black" : "",
                  }}
                >
                  {getLotteryPrize(LotteryBetTypeConst.FOUR_A).prize2}
                </div>
              </div>
            </div>
            <div className="d-flex custom-border ">
              <div className="col-4 p-2 custom-border-end">
                {intl.formatMessage({ id: "thirdPrize" })}
              </div>
              <div className="d-flex col-8">
                <div
                  className="col-4 custom-border-end p-2 fw-semibold text-end"
                  style={{
                    backgroundColor: currentQues === "b" ? "#f5be09" : "",
                    color: currentQues === "b" ? "black" : "",
                  }}
                >
                  {getLotteryPrize(LotteryBetTypeConst.BIG).prize3}
                </div>
                <div
                  className="col-4 custom-border-end p-2 fw-semibold text-end"
                  style={{
                    backgroundColor: currentQues === "s" ? "#f5be09" : "",
                    color: currentQues === "s" ? "black" : "",
                  }}
                >
                  {getLotteryPrize(LotteryBetTypeConst.SMALL).prize3}
                </div>
                <div
                  className="col-4 p-2 fw-semibold text-end"
                  style={{
                    backgroundColor: currentQues === "4a" ? "#f5be09" : "",
                    color: currentQues === "4a" ? "black" : "",
                  }}
                >
                  {getLotteryPrize(LotteryBetTypeConst.FOUR_A).prize3}
                </div>
              </div>
            </div>
            <div className="d-flex custom-border ">
              <div className="col-4 p-2  custom-border-end">
                {intl.formatMessage({ id: "special" })}
              </div>
              <div className="d-flex col-8">
                <div
                  className="col-4 custom-border-end p-2 fw-semibold text-end"
                  style={{
                    backgroundColor: currentQues === "b" ? "#f5be09" : "",
                    color: currentQues === "b" ? "black" : "",
                  }}
                >
                  {getLotteryPrize(LotteryBetTypeConst.BIG).prize4}
                </div>
                <div
                  className="col-4 custom-border-end p-2 fw-semibold text-end"
                  style={{
                    backgroundColor: currentQues === "s" ? "#f5be09" : "",
                    color: currentQues === "s" ? "black" : "",
                  }}
                >
                  {getLotteryPrize(LotteryBetTypeConst.SMALL).prize4}
                </div>
                <div
                  className="col-4 p-2 fw-semibold text-end"
                  style={{
                    backgroundColor: currentQues === "4a" ? "#f5be09" : "",
                    color: currentQues === "4a" ? "black" : "",
                  }}
                >
                  {getLotteryPrize(LotteryBetTypeConst.FOUR_A).prize4}
                </div>
              </div>
            </div>
            <div className="d-flex custom-border">
              <div className="col-4 p-2  custom-border-end">
                {intl.formatMessage({ id: "consolation" })}
              </div>
              <div className="d-flex col-8">
                <div
                  className="col-4 custom-border-end p-2 fw-semibold text-end"
                  style={{
                    backgroundColor: currentQues === "b" ? "#f5be09" : "",
                    color: currentQues === "b" ? "black" : "",
                  }}
                >
                  {getLotteryPrize(LotteryBetTypeConst.BIG).prize5}
                </div>
                <div
                  className="col-4 custom-border-end p-2 fw-semibold text-end"
                  style={{
                    backgroundColor: currentQues === "s" ? "#f5be09" : "",
                    color: currentQues === "s" ? "black" : "",
                  }}
                >
                  {getLotteryPrize(LotteryBetTypeConst.SMALL).prize5}
                </div>
                <div
                  className="col-4 p-2 fw-semibold text-end"
                  style={{
                    backgroundColor: currentQues === "4a" ? "#f5be09" : "",
                    color: currentQues === "4a" ? "black" : "",
                  }}
                >
                  {getLotteryPrize(LotteryBetTypeConst.FOUR_A).prize5}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
